import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "yearlySwitch",
    "monthlySwitch",
    "yearlyButtons",
    "monthlyButtons",
  ]

  static classes = ["active", "inactive"]

  connect() {
    this.activateYearly()
  }

  change(event) {
    if (event.currentTarget === this.yearlySwitchTarget) {
      this.activateYearly()
    } else if (event.currentTarget === this.monthlySwitchTarget) {
      this.activateMonthly()
    }
  }

  activateYearly() {
    this.yearlySwitchTarget.classList.add(...this.activeClasses)
    this.yearlySwitchTarget.classList.remove(...this.inactiveClasses)
    this.monthlySwitchTarget.classList.add(...this.inactiveClasses)
    this.monthlySwitchTarget.classList.remove(...this.activeClasses)
    this.yearlyButtonsTarget.classList.remove("hidden")
    this.monthlyButtonsTarget.classList.add("hidden")
  }

  activateMonthly() {
    this.monthlySwitchTarget.classList.add(...this.activeClasses)
    this.monthlySwitchTarget.classList.remove(...this.inactiveClasses)
    this.yearlySwitchTarget.classList.add(...this.inactiveClasses)
    this.yearlySwitchTarget.classList.remove(...this.activeClasses)
    this.monthlyButtonsTarget.classList.remove("hidden")
    this.yearlyButtonsTarget.classList.add("hidden")
  }
}
