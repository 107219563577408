import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import { Pagination, Autoplay } from "swiper/modules"
import "swiper/css"
import "swiper/css/pagination"

export default class extends Controller {
  static targets = ["swiper", "pagination", "outerWrapper"]

  static values = {
    autoplay: { type: Number, default: 0 }, // Default to 0 to disable autoplay unless specified
  }

  connect() {
    this.initSwiper()
  }

  initSwiper() {
    const outerWrapper = document.querySelectorAll(
      "[data-target=story-swiper-outerWrapper]",
    )[0]

    const swiperOptions = {
      modules: [Pagination, Autoplay],
      pagination: {
        el: this.paginationTarget,
        clickable: true,
        renderBullet: (index, className) => {
          return `<span class="${className}" data-index="${index}"></span>`
        },
      },
      on: {
        autoplayTimeLeft: (swiper, time, progress) => {
          this.updatePaginationStyles(swiper, progress)
        },
        reachEnd: () => {
          if (this.swiper.autoplay) {
            this.swiper.autoplay.stop()
          }
        },
        autoplayStop: () => {
          this.updateActivePagination(this.swiper)
        },
        slideChange: () => {
          this.updateActivePagination(this.swiper)
          this.updateBackground(this.swiper, outerWrapper)
        },
      },
    }

    if (this.autoplayValue > 0) {
      swiperOptions.autoplay = {
        delay: this.autoplayValue * 1000,
        disableOnInteraction: true,
      }
    }

    this.swiper = new Swiper(this.swiperTarget, swiperOptions)
    if (this.autoplayValue === 0) {
      this.updateActivePagination(this.swiper)
    }

    this.updateBackground(this.swiper, outerWrapper)
  }

  updatePaginationStyles(swiper, progress) {
    const bullets = swiper.pagination.bullets
    const activeIndex = swiper.activeIndex

    bullets.forEach((bullet, index) => {
      if (index < activeIndex) {
        bullet.style.background = "var(--color-blue-900)"
      } else if (index === activeIndex) {
        const progressPercentage = `${(1 - progress) * 100}%`
        const cssStyle = `linear-gradient(90deg,
          var(--color-blue-900) ${progressPercentage}, var(--color-gray-200) ${progressPercentage})`
        bullet.style.background = cssStyle // Active bullet with progress
      } else {
        bullet.style.background = "var(--color-gray-200)"
      }
    })
  }

  updateBackground(swiper, outerWrapper) {
    const color = swiper.slides[swiper.activeIndex].dataset.color
    outerWrapper.style.backgroundColor = color
  }

  updateActivePagination(swiper) {
    const bullets = swiper.pagination.bullets
    const activeIndex = swiper.activeIndex

    bullets.forEach((bullet, index) => {
      if (this.autoplayValue === 0 && index === 0) {
        // Always make the first bullet blue when autoplay is 0
        bullet.style.background = "var(--color-blue-900)"
      } else if (index < activeIndex) {
        bullet.style.background = "var(--color-blue-900)" // Left of active bullet
      } else if (index === activeIndex) {
        bullet.style.background = "var(--color-blue-900)" // Active bullet
      } else {
        bullet.style.background = "var(--color-gray-200)" // Right of active bullet
      }
    })
  }
}
