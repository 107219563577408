import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["turboFrame", "wrapper"]
  static classes = ["visibility"]

  open(event) {
    this.turboFrameTarget.src = event.currentTarget.dataset.href
    this.wrapperTarget.classList.remove(this.visibilityClass)
    if (event.currentTarget.dataset.storyColor) {
      this.wrapperTarget.classList.add(
        `bg-story-gradient-${event.currentTarget.dataset.storyColor}`,
      )
    }
  }

  close() {
    this.wrapperTarget.classList.add(this.visibilityClass)
  }
}
