import { BridgeComponent } from "@hotwired/strada"
import { Turbo } from "@hotwired/turbo-rails"
import { post } from "@rails/request.js"

export default class extends BridgeComponent {
  static component = "purchase"

  static values = {
    origin: {
      type: String,
      default: "unknown",
    },
  }

  buy(event) {
    const productId = event.params.productId
    this.send("buy", { product_id: productId }, this.buyCallback.bind(this))
  }

  manage(event) {
    const productId = event.params.productId
    this.send("manage", { product_id: productId })
  }

  async buyCallback() {
    // the purchase has successfully completed, so we could give (permanently) access
    const result = await post("/settings/plus/access/confirm")
    if (result.ok) {
      if (this.originValue === "onboarding") {
        document.body.dispatchEvent(
          new CustomEvent("page-slider:removeActive", {
            bubbles: true,
          }),
        )
      } else {
        Turbo.visit("/")
      }
    }
  }
}
