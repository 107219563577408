import plugin from "tailwindcss/plugin"

export default {
  separator: "__",
  content: [
    "./public/*.html",
    "./app/helpers/**/*.rb",
    "./app/frontend/**/*.js",
    "./app/views/**/*.slim",
    "./app/components/**/*.{rb,slim}",
    "./test/components/previews/**/*.{rb,slim}",
  ],
  safelist: [
    "bg-story-1",
    "bg-story-2",
    "bg-story-3",
    "bg-story-4",
    "bg-story-5",
    "bg-story-6",
    "bg-story-7",
    "bg-story-8",
    "bg-story-9",
    "bg-story-gradient-1",
    "bg-story-gradient-2",
    "bg-story-gradient-3",
    "bg-story-gradient-4",
    "bg-story-gradient-5",
    "bg-story-gradient-6",
    "bg-story-gradient-7",
    "bg-story-gradient-8",
    "bg-story-gradient-9",
  ],
  theme: {
    screens: {
      xs: "390px",
      sm: "410px",
      md: "768px",
      lg: "980px",
      realhover: { raw: "(hover: hover)" },
    },
    fontFamily: {
      regular: ["plus-jakarta-sans-regular"],
      semibold: ["plus-jakarta-sans-semibold"],
      bold: ["plus-jakarta-sans-bold"],
      "mint-grotesk-regular": ["mint-grotesk-regular"],
      "mint-grotesk-medium": ["mint-grotesk-medium"],
      "mint-grotesk-bold": ["mint-grotesk-bold"],
    },
    fontWeight: {
      regular: 400,
    },
    fontSize: {
      xxxs: ["0.625rem", "0.875rem"],
      xxs: ["0.75rem", "1.25rem"],
      xs: ["0.875rem", "1.375rem"],
      base: ["1rem", "1.5rem"],
      lg: ["1.125rem", "1.625rem"],
      xl: ["1.25rem", "1.75rem"],
      "2xl": ["1.5rem", "2.125rem"],
      "3xl": ["1.75rem", "2.25rem"],
      "4xl": ["2.25rem", "2.75rem"],
      "5xl": ["2.5rem", "3.125rem"],
      "6xl": ["3rem", "3.75rem"],
      "7xl": ["3.75rem", "4.75rem"],
      "8xl": ["5rem", "5.875"],
    },
    boxShadow: {
      DEFAULT:
        "0px 2px 6px 0px rgba(20, 20, 20, 0.10), 0px 0px 2px 0px rgba(20, 20, 20, 0.10)",
      card: "0px 1px 2px 0px #14141412",
      tooltip:
        "0px 0px 2px 0px rgba(20, 20, 20, 0.10), 0px 4px 20px 8px rgba(20, 20, 20, 0.10)",
      none: "none",
      "box-component-error": "inset 0 0 0 1px var(--color-orange-700)",
    },
    borderRadius: {
      xxs: "0.4375rem",
      xs: "0.625rem",
      sm: "0.75rem",
      DEFAULT: "0.5rem",
      md: "1rem",
      lg: "1.25rem",
      full: "100rem",
      none: "0",
    },
    colors: {
      orange: {
        50: "#FAEFEC",
        100: "#F9E8E1",
        200: "#F6D7CD",
        300: "#FBC1AF",
        400: "#FAA98F",
        500: "#F9906E",
        600: "#EF754F",
        700: "#E5592E",
        800: "#C0451E",
        900: "#9B3110",
      },
      blue: {
        50: "#E8E9F3",
        100: "#D5D8EB",
        200: "#B7BCD6",
        300: "#8F95BF",
        400: "#6C74A6",
        500: "#596297",
        600: "#434B7D",
        700: "#2F355F",
        800: "#1E254E",
        900: "#101431",
      },
      lavender: {
        50: "#EFEEF7",
        100: "#DFDCEF",
        200: "#C0BADE",
        300: "#A097CE",
        400: "#7A6DBA",
        500: "#6456A9",
        600: "#4D4087",
        700: "#393166",
        800: "#262142",
        900: "#141124",
      },
      green: {
        50: "#F3F6EC",
        100: "#E7EFD8",
        200: "#D4DFC0",
        300: "#BAC89F",
        400: "#A9BD83",
        500: "#8BA160",
        600: "#6B7E46",
        700: "#53652F",
        800: "#42561C",
        900: "#324311",
      },
      gray: {
        50: "#F9FAFB",
        100: "#F3F4F6",
        200: "#E5E7EB",
        300: "#D1D5DB",
        400: "#9CA3AF",
        500: "#6b7280",
        600: "#4B5563",
        700: "#374151",
        800: "#1F2937",
        900: "#111827",
      },
      stone: {
        50: "#FDFCFB",
        100: "#FAF7F5",
        200: "#F6F2EF",
        300: "#F4ECE7",
        400: "#ECE0D9",
        500: "#E4D5CD",
        600: "#E1C9BC",
        700: "#CDB09F",
        800: "#BC9B89",
        900: "#A88571",
      },
      pink: {
        50: "#fdf2f8",
        100: "#fce7f3",
        200: "#fbcfe8",
        300: "#f9a8d4",
        400: "#f472b6",
        500: "#ec4899",
        600: "#db2777",
        700: "#be185d",
        800: "#9d174d",
        900: "#831843",
      },
      story: {
        1: "#C0BADE",
        2: "#DAB8C3",
        3: "#E3D0C6",
        4: "#E5D8B5",
        5: "#B5CAB0",
        6: "#A9CEE1",
        7: "#B2B7D6",
        8: "#E7CBE1",
        9: "#F9CEC2",
        10: "#5363C3",
      },
      yellow: "#FEBA46",
      black: "#000000",
      white: "#ffffff",
      transparent: "transparent",
      currentColor: "currentColor",
    },
    extend: {
      transitionProperty: {
        width: "width",
        height: "height",
      },
      spacing: {
        0.75: "0.1875rem",
        2.75: "0.6875rem",
        18: "4.5rem",
        25: "6.25rem",
        30: "7.5rem",
      },
      maxWidth: {
        app: "37.5rem",
        "app-with-padding": "calc(37.5rem - 2rem)",
        "4xl": "62rem",
        "6xl": "85rem",
      },
      minHeight: {
        10: "2.5rem",
      },
      lineHeight: {
        4.5: "1.125rem",
        6.5: "1.625rem",
      },
      borderWidth: {
        10: "10px",
      },
      backgroundImage: {
        page: 'url("/images/page-background.svg")',
        start: 'url("/images/start-background.jpg")',
        intro0: 'url("/images/intro/slide-0.png")',
        intro1: 'url("/images/intro/slide-1.png")',
        intro2: 'url("/images/intro/slide-2.png")',
        "dummy-chart-combined": 'url("/images/dummy-chart-combined.svg")',
        "dummy-chart-pie": 'url("/images/dummy-chart-pie.svg")',
        "dummy-chart-bars": 'url("/images/dummy-chart-bars.svg")',
        "button-gradient":
          "linear-gradient(218.31deg, #FAA98F 22.07%, #EF754F 78.62%)",
        "orange-gradient":
          "linear-gradient(270deg, #E6592E 5%, var(--color-orange-600) 50%, #FF9559 75%, var(--color-orange-500) 95%)",
        "lavender-gradient":
          "linear-gradient(270deg, #766AB5 5%, #8C7ED6 50%, #9577D5 75%, #887BC8 95%)",
        "orange-gradient-button":
          "linear-gradient(225deg, var(--color-orange-400) 20%, var(--color-orange-600) 80%)",
        "lavender-gradient-button":
          "linear-gradient(270deg, #A093E2 35%, #887BC8 60%)",
        "blue-orange-gradient":
          "linear-gradient(60deg, var(--color-blue-500) 5%, var(--color-orange-400) 95%)",
        "blue-orange-gradient-banner":
          "linear-gradient(20deg, var(--color-blue-500) 10%, var(--color-orange-400) 85%)",
        "story-gradient-1":
          "linear-gradient(var(--color-story-1) 0, var(--color-stone-200) 100%)",
        "story-gradient-2":
          "linear-gradient(var(--color-story-2) 0, var(--color-stone-200) 100%)",
        "story-gradient-3":
          "linear-gradient(var(--color-story-3) 0, var(--color-stone-200) 100%)",
        "story-gradient-4":
          "linear-gradient(var(--color-story-4) 0, var(--color-stone-200) 100%)",
        "story-gradient-5":
          "linear-gradient(var(--color-story-5) 0, var(--color-stone-200) 100%)",
        "story-gradient-6":
          "linear-gradient(var(--color-story-6) 0, var(--color-stone-200) 100%)",
        "story-gradient-7":
          "linear-gradient(var(--color-story-7) 0, var(--color-stone-200) 100%)",
        "story-gradient-8":
          "linear-gradient(var(--color-story-8) 0, var(--color-stone-200) 100%)",
        "story-gradient-9":
          "linear-gradient(var(--color-story-9) 0, var(--color-stone-200) 100%)",
        "story-gradient-10":
          "linear-gradient(272.62deg, #4353B0 5%, var(--color-story-10) 35%, #6174E7 75%, #6573CA 95%)",
      },
      zIndex: {
        infinity: "calc(infinity)",
        60: "60",
        65: "65", // Audio Transkription Overlay (when in Program view must overlay the tabbar)
        70: "70", // Audio Player
        80: "80", // Story
      },
    },
  },
  plugins: [
    plugin(function ({ addVariant }) {
      const createPlatformVariant = (platform) => {
        addVariant(platform, ({ modifySelectors, separator }) => {
          modifySelectors(({ className }) => {
            return `html.platform-${platform} .${platform}${separator}${className}`
          })
        })
      }
      createPlatformVariant("ios")
      createPlatformVariant("android")
    }),
    ({ addBase, theme }) => {
      function extractColorVars(colorObj, colorGroup = "") {
        return Object.keys(colorObj).reduce((vars, colorKey) => {
          const newVars =
            typeof colorObj[colorKey] === "string"
              ? { [`--color${colorGroup}-${colorKey}`]: colorObj[colorKey] }
              : extractColorVars(colorObj[colorKey], `-${colorKey}`)
          return { ...vars, ...newVars }
        }, {})
      }
      addBase({ ":root": extractColorVars(theme("colors")) })
    },
  ],
}
