import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "button",
    "migraine",
    "medication",
    "prevention",
    "mood",
    "custom",
  ]

  static values = {
    tab: { type: String, default: "migraine" },
  }

  static classes = ["active", "buttonActive"]

  connect() {
    this.updateTabs(this.tabValue)
  }

  select({ params: { tab } }) {
    this.tabValue = tab
  }

  tabValueChanged(newTab) {
    this.updateTabs(newTab)
  }

  updateTabs(activeTab) {
    this.buttonTargets.forEach((button) => {
      if (button.dataset.analysisTabsTabParam === activeTab) {
        button.classList.add(...this.buttonActiveClasses)
      } else {
        button.classList.remove(...this.buttonActiveClasses)
      }
    })

    this.toggleVisibility(this.migraineTarget, activeTab === "migraine")
    this.toggleVisibility(this.medicationTarget, activeTab === "medication")
    this.toggleVisibility(this.preventionTarget, activeTab === "prevention")
    this.toggleVisibility(this.customTarget, activeTab === "custom")
    this.toggleVisibility(this.moodTarget, activeTab === "mood")
  }

  toggleVisibility(target, shouldShow) {
    if (target) {
      target.classList.toggle(this.activeClass, !shouldShow)
    }
  }
}
