import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["banner"]

  hide() {
    this.bannerTarget.classList.add("hidden")
    localStorage.setItem("app-intro-banner-seen", true)
  }

  connect() {
    document.addEventListener("turbo:frame-load", this.checkBanner.bind(this))
    this.checkBanner()
  }

  checkBanner() {
    if (
      this.hasBannerTarget &&
      !localStorage.getItem("app-intro-banner-seen")
    ) {
      this.bannerTarget.classList.remove("hidden")
    }
  }
}
